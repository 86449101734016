import * as React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"

import { MEDIA_MOBILE } from "../const"
import { NewsListItemFragment } from "../../types/graphql-types"

type Props = {
  edges: {
    node: NewsListItemFragment
  }[]
}

const List: React.FC<Props> = ({ edges }) => (
  <Wrapper>
    {edges.map(({ node }) => (
      <Item to={`/news/post/${node.slug}/`} key={node.id}>
        <div>
          <PublishedDate>{node.publishDateFormated}</PublishedDate>
          <Title>{node.title}</Title>
        </div>
      </Item>
    ))}
  </Wrapper>
)

export default List

export const query = graphql`
  fragment NewsListItem on ContentfulNewsPost {
    id
    title
    slug
    publishDateFormated: publishDate(formatString: "YYYY-MM-DD")
    updateDateFormated: updateDate(formatString: "YYYY-MM-DD")
  }
`

const Wrapper = styled.dl`
  border-top: 1px solid #666;
`
const Item = styled(Link)`
  display: block;
  position: relative;

  > div {
    display: flex;
    padding: 16px 0;
    padding-right: 10px;
    border-bottom: 1px solid #666;

    @media (${MEDIA_MOBILE}) {
      display: block;
    }
  }

  &::after {
    display: block;
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-width: 1px 1px 0 0;
    border-color: #666;
    border-style: solid;
    right: 5px;
    top: 50%;
    transform: rotate(45deg) translateY(-50%);
    transition: right 0.2s linear;
  }

  &:hover::after {
    right: 0px;
  }
`
const PublishedDate = styled.dt`
  flex: 0 0 6em;
  line-height: 1.6;
`
const Title = styled.dd`
  line-height: 1.6;
`
