import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import { MEDIA_MOBILE } from "../const"
import Layout from "../components/layout"
import Heading2 from "../components/heading2"
import Metadata from "../components/metadata"
import NewsList from "../components/news-list"

import { NewsListQuery } from "../../types/graphql-types"
import { NewsListPageContext } from "../gatsby-node"

interface Props {
  data: NewsListQuery
  pageContext: NewsListPageContext
  location: Location
}

const Post: React.FC<Props> = ({ data, pageContext, location }) => {
  return (
    <Layout>
      <Metadata
        title="News"
        pagePath={location.pathname}
        description="ぁゃぴのお知らせ一覧"
      />
      <Wrapper>
        <Heading2 feedUrl="/rss-news.xml">News</Heading2>
        <NewsList edges={data.news.edges} />
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query NewsList($skip: Int!, $limit: Int!) {
    news: allContentfulNewsPost(
      sort: { fields: updateDate, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          ...NewsListItem
        }
      }
    }
  }
`

export default Post

const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: 40em;
  margin: 2rem auto;

  @media (${MEDIA_MOBILE}) {
    width: 100%;
    padding: 0 20px;
    padding-top: 0;
  }
`
